import { useContext } from "react";
import {
  HiOutlineArchive,
  HiOutlineFingerPrint,
  HiOutlineRefresh,
  HiPaperAirplane,
} from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import { CheckListStatusLabel } from "components/checkmore/composite";
import CheckStatusLabel from "components/checkmore/composite/CheckStatusLabel";
import { Button, Divider, Icon, IconType, Link } from "components/common/basic";
import { AttachmentFragment, CheckListFragment } from "graphql/fragments";
import {
  useArchiveCheckListsMutation,
  useCreateCheckRequestMutation,
  useRemindCheckListsMutation,
  useUnarchiveCheckListsMutation,
} from "graphql/mutations";
import {
  CheckListEventTypeEnum,
  CheckListStatusEnum,
  CheckStatusEnum,
  CheckTypeEnum,
  TeamRoleEnum,
} from "graphql/types";
import { AuthContext } from "providers/Authentication";
import { PopupContext } from "providers/PopupHandler";
import { TailwindContext } from "providers/Tailwind";
import { useTranslation } from "translations";
import { atDateToMoment, useRoutePaths } from "utils";
import { tw } from "utils/tw";

interface InfoRow {
  icon: IconType;
  label: string;
  details: JSX.Element;
  files?: AttachmentFragment[];
  status?: CheckStatusEnum;
  dateString?: string;
}

interface Props {
  checkList: CheckListFragment;
  viewer: "sender" | "receiver";
  overrideCRC?: () => void;
}

export default ({ checkList, viewer, overrideCRC }: Props) => {
  const { t } = useTranslation("checkmore");
  const { remindCheckLists } = useRemindCheckListsMutation();
  const { createCheckRequest } = useCreateCheckRequestMutation();
  const { archiveCheckLists } = useArchiveCheckListsMutation();
  const { unarchiveCheckLists } = useUnarchiveCheckListsMutation();
  const getRoutePath = useRoutePaths();
  const navigate = useNavigate();
  const { openPopup } = useContext(PopupContext);
  const { isMd } = useContext(TailwindContext);
  const { session } = useContext(AuthContext);

  const latestEvent = checkList.events[0];
  if (!latestEvent) return null;

  const overrideEvent = checkList.events.find(
    ({ eventType }) => eventType === CheckListEventTypeEnum.CheckOverridden
  );

  const infoRows: InfoRow[] = [
    {
      icon: "HiUser" as const,
      label: t("checkResultpopup.result.name", "Name:"),
      details: <p className={tw("text-sm")}>{checkList.name.full}</p>,
    },
    ...(checkList.status === CheckListStatusEnum.Refused
      ? [
          {
            icon: "HiExclamation" as const,
            label: t(
              "checkResultpopup.result.refuseReason",
              "Reason for refusal:"
            ),
            details: <p className={tw("text-sm")}>{checkList.refuseReason}</p>,
          },
        ]
      : checkList.checks.reduce((acc: InfoRow[], check, index) => {
          const latestCheckEvent =
            check.events.length > 0 ? check.events[0] : undefined;
          const formattedFullName = check.identityAttributes?.identity
            ? `${check.identityAttributes.identity.firstName} ${check.identityAttributes.identity.lastName}`
            : "";

          switch (check.type) {
            case CheckTypeEnum.BankId:
              return [
                ...acc,
                {
                  icon: "HiIdentification" as const,
                  label: t(
                    "checkResultpopup.result_bankID.status",
                    "ID-verification:"
                  ),
                  details: (
                    <p className={tw("text-sm", "whitespace-pre-wrap")}>
                      {t(
                        "checkResultpopup.result.result_bankId.details",
                        "Verification through BankID\n{{ name }}",
                        {
                          name: formattedFullName,
                        }
                      )}
                    </p>
                  ),
                  status: check.status,
                  dateString:
                    latestCheckEvent &&
                    atDateToMoment(latestCheckEvent.occurredAt).format(
                      "DD MMM YYYY - HH:mm"
                    ),
                },
              ];

            case CheckTypeEnum.Identity:
              return [
                ...acc,
                {
                  icon: "HiIdentification" as const,
                  label: t(
                    "checkResultpopup.result_identity.status",
                    "ID-verification:"
                  ),
                  details: (
                    <p className={tw("text-sm", "whitespace-pre-wrap")}>
                      {t(
                        "checkResultpopup.result_identity.details",
                        "Verification of identity\n{{ name }}",
                        {
                          name: formattedFullName,
                        }
                      )}
                    </p>
                  ),
                  status: check.status,
                  dateString:
                    latestCheckEvent &&
                    atDateToMoment(latestCheckEvent.occurredAt).format(
                      "DD MMM YYYY - HH:mm"
                    ),
                },
              ];

            case CheckTypeEnum.GlobalIdentity:
              return [
                ...acc,
                {
                  icon: "HiIdentification" as const,
                  label: t(
                    "checkResultpopup.result_globalIdentity.status",
                    "ID-verification:"
                  ),
                  details: <></>,
                  status: check.status,
                  dateString:
                    latestCheckEvent &&
                    atDateToMoment(latestCheckEvent.occurredAt).format(
                      "DD MMM YYYY - HH:mm"
                    ),
                },
              ];

            case CheckTypeEnum.BusinessInterests:
              return [
                ...acc,
                {
                  icon: "HiOfficeBuilding" as const,
                  label: t(
                    "checkResultpopup.result_businessInterests.status",
                    "Business interest check:"
                  ),
                  details: <></>,
                  status: check.status,
                  dateString:
                    latestCheckEvent &&
                    atDateToMoment(latestCheckEvent.occurredAt).format(
                      "DD MMM YYYY - HH:mm"
                    ),
                },
              ];

            case CheckTypeEnum.Education:
              return [
                ...acc,
                {
                  icon: "HiAcademicCap" as const,
                  label: t(
                    "checkResultpopup.result_education.status",
                    "Education verification:"
                  ),
                  details: (
                    <p className={tw("text-sm", "whitespace-pre-wrap")}>
                      {t(
                        "checkResultpopup.result_education.requirements",
                        "Required: {{ requirements }}",
                        {
                          requirements:
                            checkList.checkRequest.educationRequirements,
                        }
                      )}
                    </p>
                  ),
                  status: check.status,
                  dateString:
                    latestCheckEvent &&
                    atDateToMoment(latestCheckEvent.occurredAt).format(
                      "DD MMM YYYY - HH:mm"
                    ),
                },
              ];

            case CheckTypeEnum.WorkExperience:
              return [
                ...acc,
                {
                  icon: "HiBriefcase" as const,
                  label: t(
                    "checkResultpopup.result_workExperience.status",
                    "Employment verification:"
                  ),
                  details: (
                    <ul>
                      {check.workExperienceAttributes?.workExperiences?.map(
                        (exp) => (
                          <li className={tw("text-sm")}>{exp.company}</li>
                        )
                      )}
                    </ul>
                  ),
                  status: check.status,
                  dateString:
                    latestCheckEvent &&
                    atDateToMoment(latestCheckEvent.occurredAt).format(
                      "DD MMM YYYY - HH:mm"
                    ),
                },
              ];

            case CheckTypeEnum.CriminalRecord:
              return [
                ...acc,
                {
                  icon: "HiShieldCheck" as const,
                  label: t(
                    "checkResultpopup.result_criminalRecord.status",
                    "Criminal record certificate:"
                  ),
                  details: (
                    <div className={tw("flex", "flex-col")}>
                      {overrideEvent ? (
                        <p className={tw("text-sm", "whitespace-pre-wrap")}>
                          {t(
                            "checkResultpopup.result_criminalRecord.overridden",
                            "Overridden by: {{ actorName }}\nWhy: {{ note }}",
                            {
                              actorName: overrideEvent.actorName,
                              note: overrideEvent.note,
                            }
                          )}
                        </p>
                      ) : (
                        <p className={tw("text-sm")}>
                          {t(
                            "checkResultpopup.result_criminalRecord.details",
                            "Verification of CRC"
                          )}
                        </p>
                      )}

                      {checkList.purposeLetterPath && (
                        <Link
                          id="check_result_popup-go_to_purpose_letter"
                          isExternal
                          to={`${process.env.REACT_APP_API_URL}${checkList.purposeLetterPath}`}
                          color="deepBlue"
                          className={tw("text-sm")}
                        >
                          {t(
                            "checkResultpopup.result_criminalRecord.purposeLetter.label",
                            "View purpose letter"
                          )}
                        </Link>
                      )}

                      {viewer === "receiver" &&
                        [CheckStatusEnum.Requested].includes(
                          checkList.checks[index].status
                        ) &&
                        !checkList.checks
                          .slice(0, index)
                          .some(
                            (check) =>
                              ![
                                CheckStatusEnum.InReview,
                                CheckStatusEnum.Cleared,
                              ].includes(check.status)
                          ) && (
                          <Link
                            to={getRoutePath({
                              module: "checkmore",
                              routeName: "CHECK_WIZARD",
                              arg1: checkList.id,
                            })}
                            id="check_result_popup-go_to_wizard"
                            unstyled
                            className={tw("mt-2")}
                          >
                            <Button
                              id="check_result_popup-go_to_wizard"
                              variant="primary"
                              size="sm"
                            >
                              {t(
                                "checkResultpopup.result_criminalRecord.goToCRCUpload.label",
                                "Upload criminal record certificate"
                              )}
                            </Button>
                          </Link>
                        )}

                      {check.criminalRecordAttributes?.files &&
                        check.criminalRecordAttributes.files.length > 0 && (
                          <Link
                            id="check_result_popup-go_to_file_upload"
                            isExternal
                            to={`${process.env.REACT_APP_API_URL}${check.criminalRecordAttributes.files[0].downloadPath}`}
                            unstyled
                            className={tw("mt-2")}
                          >
                            <Button
                              id="check_result_popup-go_to_file_upload"
                              variant="tertiary"
                              size="sm"
                            >
                              {t(
                                "checkResultpopup.result_criminalRecord.uploadedFile.label",
                                "View uploaded file"
                              )}
                            </Button>
                          </Link>
                        )}
                    </div>
                  ),
                  status: check.status,
                  dateString:
                    latestCheckEvent &&
                    atDateToMoment(latestCheckEvent.occurredAt).format(
                      "DD MMM YYYY - HH:mm"
                    ),
                },
              ];

            case CheckTypeEnum.Information:
              return [
                ...acc,
                {
                  icon: "HiDocumentText" as const,
                  label: t(
                    "checkResultpopup.result_documentConfirmation.status",
                    "Read confirmation:"
                  ),
                  details: (
                    <p className={tw("text-sm")}>
                      {t(
                        "checkResultpopup.result_documentConfirmation.details",
                        "Documents that are confirmed read:"
                      )}
                    </p>
                  ),
                  files: check.informationAttributes?.documents,
                  status: check.status,
                  dateString:
                    latestCheckEvent &&
                    atDateToMoment(latestCheckEvent.occurredAt).format(
                      "DD MMM YYYY - HH:mm"
                    ),
                },
              ];

            case CheckTypeEnum.ELearning:
              return [
                ...acc,
                {
                  icon: "HiAcademicCap" as const,
                  label: t(
                    "checkResultpopup.result_eLearning.status",
                    "E-learning"
                  ),
                  details: (
                    <p className={tw("text-sm")}>
                      {t(
                        "checkResultpopup.result_eLearning.details",
                        'Course: "Anti child abuse"'
                      )}
                    </p>
                  ),
                  files: check.eLearningAttributes?.documents,
                  status: check.status,
                  dateString:
                    latestCheckEvent &&
                    atDateToMoment(latestCheckEvent.occurredAt).format(
                      "DD MMM YYYY - HH:mm"
                    ),
                },
              ];

            case CheckTypeEnum.SpecificSourceSearch:
              return [
                ...acc,
                {
                  icon: "HiOutlineSearchCircle" as const,
                  label: t(
                    "checkResultpopup.result_specificSourceSearch.status",
                    "Check: Specific Check PEP"
                  ),
                  details: <></>,
                  status: check.status,
                },
              ];

            default:
              return acc;
          }
        }, [])),
  ];

  const finalizedStatuses = [
    CheckListStatusEnum.Refused,
    CheckListStatusEnum.Rejected,
    CheckListStatusEnum.Cancelled,
    CheckListStatusEnum.Cleared,
    CheckListStatusEnum.InReview,
  ];
  const showStartButton =
    viewer === "receiver" && checkList.status === CheckListStatusEnum.Requested;
  const showContinueButton =
    viewer === "receiver" && !finalizedStatuses.includes(checkList.status);
  const showRemindButton =
    viewer === "sender" && !finalizedStatuses.includes(checkList.status);
  const showCreateNewButton =
    viewer === "sender" && finalizedStatuses.includes(checkList.status);
  const showArchiveButton =
    viewer === "sender" && finalizedStatuses.includes(checkList.status);

  const CRC = checkList.checks.find(
    ({ type }) => type === CheckTypeEnum.CriminalRecord
  );
  // Should use same logic as in ChecksTable
  const hasOverridableCRC =
    CRC &&
    [
      CheckStatusEnum.Requested,
      CheckStatusEnum.InReview,
      CheckStatusEnum.Rejected,
    ].includes(CRC.status);
  const showOverrideCRCButton =
    viewer === "sender" &&
    overrideCRC &&
    session?.role !== TeamRoleEnum.Member &&
    hasOverridableCRC;

  const createAndGoToCheckRequest = () =>
    createCheckRequest(checkList.checkRequest.type, ({ id }) =>
      navigate(
        getRoutePath({
          module: "checkmore",
          routeName: "CHECK_TYPE_SELECTION",
          arg1: id,
        })
      )
    );

  return (
    <div className={tw("p-4", "flex", "flex-col", "space-y-4")}>
      <div className={tw("flex", "justify-between")}>
        <div className={tw("flex", "flex-col", "space-y-4", "items-start")}>
          <div className={tw("space-y-1")}>
            <h4 className={tw("text-lg", "font-bold", "text-deepBlue-900")}>
              {t("checkResultpopup.result.heading", "Result")}
            </h4>

            <button
              onClick={() =>
                openPopup({
                  id: "CheckLog",
                  variant: "slideOver",
                  props: { checkList },
                })
              }
              className={tw(
                "text-deepBlue-900",
                "text-sm",
                "underline",
                "text-left"
              )}
            >
              {t(
                "checkResultpopup.result.heading.button.openCheckLog.label",
                "See detailed log"
              )}
            </button>
          </div>
        </div>

        <div
          className={tw(
            "flex",
            "flex-col",
            "space-y-2",
            "items-end",
            "shrink-0"
          )}
        >
          <CheckListStatusLabel
            viewer={viewer}
            badgeSize="sm"
            variant="tag"
            status={checkList.status}
          />

          <p className={tw("text-deepBlue-500", "text-xs")}>
            {t(
              "checkResultpopup.result.latestEventAt",
              "Last updated on: {{ latestEventAt }}",
              {
                latestEventAt: atDateToMoment(latestEvent.occurredAt).format(
                  "DD MMM YYYY - HH:mm"
                ),
              }
            )}
          </p>
        </div>
      </div>

      <div className={tw("flex", "flex-col", "md:flex-row", "gap-4")}>
        {showCreateNewButton && (
          <Button
            id="check_result_popup-create_new"
            variant="tertiary"
            size="md"
            fullWidth={!isMd}
            LeadingIcon={HiOutlineFingerPrint}
            onClick={createAndGoToCheckRequest}
          >
            {t("checkResultpopup.createNewRequest", "Create new request")}
          </Button>
        )}

        {showArchiveButton && (
          <Button
            id="check_result_popup-archive"
            variant="tertiary"
            size="md"
            fullWidth={!isMd}
            LeadingIcon={HiOutlineArchive}
            onClick={
              checkList.archivedAt
                ? () => unarchiveCheckLists({ ids: [checkList.id] })
                : () => archiveCheckLists({ ids: [checkList.id] })
            }
          >
            {checkList.archivedAt
              ? t("checkResultpopup.unarchive", "Move to active")
              : t("checkResultpopup.archive", "Move to archive")}
          </Button>
        )}

        {showRemindButton && (
          <Button
            id="check_result_popup-send_reminder"
            variant="tertiary"
            size="md"
            fullWidth={!isMd}
            LeadingIcon={HiPaperAirplane}
            onClick={() =>
              openPopup({
                id: "ConfirmAction",
                props: {
                  onConfirmation: () =>
                    remindCheckLists({ ids: [checkList.id] }),
                  confirmationLabel: t(
                    "checkResultpopup.sendReminder.confirmation.buttonLabel",
                    "Send reminder"
                  ),
                  confirmationHeading: t(
                    "checkResultpopup.sendReminder.confirmation.heading",
                    "Are you sure you want to send a new reminder?"
                  ),
                  confirmationBody: t(
                    "checkResultpopup.sendReminder.confirmation.body",
                    "Manymore will send a reminder to {{ receiverName }} to do the check.",
                    { receiverName: checkList.name.full }
                  ),
                },
              })
            }
          >
            {t(
              "checkResultpopup.result.heading.button.sendReminder.label",
              "Send reminder"
            )}
          </Button>
        )}

        {showOverrideCRCButton && (
          <Button
            id="check_result_popup-override_crc"
            variant="tertiary"
            size="md"
            fullWidth={!isMd}
            LeadingIcon={HiOutlineRefresh}
            onClick={overrideCRC}
          >
            {t(
              "checkResultpopup.result.heading.button.overrideCRC.label",
              "Override CRC"
            )}
          </Button>
        )}

        {(showStartButton || showContinueButton) && (
          <Link
            to={getRoutePath({
              module: "checkmore",
              routeName: "CHECK_WIZARD",
              arg1: checkList.id,
            })}
            id="check_result_popup-go_to_wizard"
            unstyled
          >
            <Button
              id="check_result_popup-go_to_wizard"
              variant="primary"
              size="md"
              fullWidth={!isMd}
              // LeadingIcon={HiOutlineCheckCircle}
            >
              {showStartButton
                ? t(
                    "checkResultpopup.result.heading.button.goToWizard_start.label",
                    "Start check"
                  )
                : t(
                    "checkResultpopup.result.heading.button.goToWizard_continue.label",
                    "Continue check"
                  )}
            </Button>
          </Link>
        )}
      </div>

      <Divider />

      <div className={tw("flex", "flex-col", "space-y-6", "text-deepBlue-500")}>
        {infoRows.map(
          ({ icon, label, details, files, status, dateString }, index) => (
            <div key={index} className={tw("flex", "justify-between")}>
              <div
                className={tw("flex", "flex-col", "space-y-1", "items-start")}
              >
                <div className={tw("flex", "space-x-4", "items-center")}>
                  <Icon icon={icon} size={16} />

                  <h5 className={tw("font-semibold")}>{label}</h5>
                </div>

                <div className={tw("pl-8", "flex", "flex-col")}>
                  {details}

                  {files && (
                    <ul>
                      {files.map(({ id, downloadPath, filename }) => (
                        <li key={id}>
                          <Link
                            id="check_result_popup-go_to_file_upload"
                            isExternal
                            to={`${process.env.REACT_APP_API_URL}${downloadPath}`}
                            color="deepBlue"
                            className={tw("text-sm")}
                          >
                            {filename}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>

              <div
                className={tw(
                  "flex",
                  "flex-col",
                  "space-y-2",
                  "items-end",
                  "shrink-0"
                )}
              >
                {status && (
                  <CheckStatusLabel
                    viewer={viewer}
                    badgeSize="sm"
                    variant="tag"
                    status={status}
                  />
                )}

                {dateString && (
                  <p className={tw("text-deepBlue-500", "text-xs")}>
                    {dateString}
                  </p>
                )}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};
