import { gql } from "@apollo/client";

import {
  ActorEnum,
  CheckList,
  CheckListEventTypeEnum,
  CheckListStatusEnum,
  CriminalRecordCheckList,
} from "graphql/types";

import { ATTACHMENT_FRAGMENT, AttachmentFragment } from "./attachment";
import { CHECK_FRAGMENT, CheckFragment } from "./check";
import { CheckRequestFragment } from "./checkRequest";
import { CRC_PURPOSE_FRAGMENT, CrcPurposeFragment } from "./crcPurpose";
import { DEPARTMENT_FRAGMENT, DepartmentFragment } from "./department";
import { NAME_FRAGMENT, NameFragment } from "./name";
import { SIMPLIFIED_ACCOUNT_FRAGMENT } from "./simplifiedAccount";

export const CHECK_LIST_FRAGMENT = gql`
  fragment CheckListFragment on CheckList {
    id
    checkRequest {
      id
      type
      message
      eLearningCourse
      account {
        ...SimplifiedAccountFragment
      }
      files {
        ...AttachmentFragment
      }
      crcPurpose {
        ...CrcPurposeFragment
      }
      countries
      educationRequirements
    }
    checks {
      ...CheckFragment
    }
    events {
      eventType
      status
      occurredAt
      actor
      actorName
      note
    }
    department {
      ...DepartmentFragment
    }
    departments {
      ...DepartmentFragment
    }
    status
    requestedAt
    lastContactedAt
    archivedAt
    email
    name {
      ...NameFragment
    }
    phone
    refuseReason
    purposeLetterPath
    criminalRecordAttributes {
      alreadyEmployed
      temporaryJob
      employedOn
      jobDescription
      jobRole
    }
  }
  ${ATTACHMENT_FRAGMENT}
  ${CHECK_FRAGMENT}
  ${DEPARTMENT_FRAGMENT}
  ${SIMPLIFIED_ACCOUNT_FRAGMENT}
  ${CRC_PURPOSE_FRAGMENT}
  ${NAME_FRAGMENT}
`;

interface ChecklistRequest
  extends Pick<
    CheckRequestFragment,
    | "id"
    | "type"
    | "message"
    | "eLearningCourse"
    | "account"
    | "crcPurpose"
    | "countries"
    | "educationRequirements"
  > {
  files: AttachmentFragment[];
  crcPurpose: CrcPurposeFragment;
}

export interface CheckListFragment
  extends Pick<
    CheckList,
    | "id"
    | "events"
    | "status"
    | "requestedAt"
    | "lastContactedAt"
    | "archivedAt"
    | "email"
    | "name"
    | "phone"
    | "refuseReason"
    | "purposeLetterPath"
    | "criminalRecordAttributes"
  > {
  name: NameFragment;
  checkRequest: ChecklistRequest;
  checks: CheckFragment[];
  events: {
    eventType: CheckListEventTypeEnum;
    status: CheckListStatusEnum;
    occurredAt: string;
    actor: ActorEnum;
    actorName?: string;
    note?: string;
  }[];
  department: DepartmentFragment;
  departments: DepartmentFragment[];
  criminalRecordAttributes?: Pick<
    CriminalRecordCheckList,
    | "alreadyEmployed"
    | "temporaryJob"
    | "employedOn"
    | "jobDescription"
    | "jobRole"
  >;
}
